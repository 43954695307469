.contact-form {
    font-family: 'Rubik', Arial, sans-serif;
    background-color: var(--brand-light-gray);

    &__wrapper {
        display: flex;
        gap: 1.875rem;
        padding: 1.875rem;
        margin: -2.5rem 0 3.75rem;
        border-radius: 0.5rem;
        border: 1px solid var(--brand-gray);
        background-color: var(--white);
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
    }

    &__map {
        flex-grow: 1;
        flex-basis: 50%;

        &-figure {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 460px;
            border-radius: 0.5rem;
            border: 1px solid var(--brand-gray);
        }

        &-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__form {
        display: flex;
        gap: 1.875rem;
        flex-grow: 1;
        flex-basis: 50%;

        .inquiry-form {
            padding: 0;
            border-radius: 0;
            border: none;
            box-shadow: none;
            flex-grow: 1;
            flex-basis: 50%;

            & > .btn {
                justify-content: center;
                width: 100%;
            }
        }
    }

    &__info {
        padding-top: 2.5rem;
        flex-grow: 1;
        flex-basis: 50%;

        &-list {
            & > li {
                &:not(:last-child) {
                    margin-bottom: 0.5rem;
                }

                & > span {
                    font-weight: 500;
                }
            }
        }

        &-social {
            margin-top: 1.5rem;
            padding-top: 1.5rem;
            border-top: 1px solid var(--brand-gray);

            & > div:first-child {
                font-weight: 500;
                margin-bottom: 0.5rem;
            }
        }

        .social {
            font-size: 1.5rem;
        }
    }
}

@media screen and (max-width: 1619px) {
    .contact-form {
        &__map {
            flex-basis: 33.33333%;

            &-figure {
                // padding-top: 460px;
            }
        }

        &__form {
            flex-basis: 66.66667%;
        }
    }
}
@media screen and (max-width: 1239px) {
    .contact-form {
        &__wrapper {
            flex-wrap: wrap;
        }

        &__map {
            flex-basis: 100%;

            &-figure {
                padding-top: 360px;
            }
        }

        &__form {
            flex-basis: 100%;
        }
    }
}
@media screen and (max-width: 991px) {
    .contact-form {
        &__wrapper {
            flex-wrap: wrap;
        }

        &__map {
            flex-basis: 100%;

            &-figure {
                padding-top: 360px;
            }
        }

        &__form {
            flex-basis: 100%;
            flex-wrap: wrap;

            & > .inquiry-form {
                order: 1;
                flex-basis: 100%;
            }
        }

        &__info {
            order: 0;
            flex-basis: 100%;
            padding-top: 0;
        }
    }
}
@media screen and (max-width: 767.98px) {
}
@media screen and (max-width: 575.98px) {
    .contact-form {
        &__wrapper {
            padding: 1rem;
        }
    }
}
