.min-max {
    &__input {
        position: relative;

        & > input {
            padding: 0.625rem 1.875rem 0.625rem 0.75rem;

            &:focus {
                & + svg {
                    color: var(--brand-cyan);
                }
            }

            &.warning {
                border-color: var(--red);
                background-color: rgba(#dc3545, 0.1);

                & + svg {
                    color: var(--red);
                }
            }
        }

        & > svg {
            position: absolute;
            top: 50%;
            right: 0.75rem;
            color: var(--brand-mid-gray);
            transform: translateY(-50%);
        }
    }
}