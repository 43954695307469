.blog {
    .inner-page-content__main > *:not(:last-child) {
        margin-bottom: 0;
    }

    &__listing-heading {
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid var(--brand-mid-gray);
        font-size: 1.5rem;
    }
}
