.add-property-form {
    background-color: var(--brand-light-gray);

    &__form {
        margin: -2.5rem 0 3.75rem;
        padding: 1.875rem;
        border-radius: 0.5rem;
        border: 1px solid var(--brand-gray);
        background-color: var(--white);
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
    }

    &__heading {
        margin-bottom: 1.875rem;
        padding-bottom: 0.9375rem;
        border-bottom: 1px solid var(--brand-mid-gray);

        & > small {
            padding-left: 0.5rem;
            color: var(--brand-dark-gray);
            font-size: 1rem;
        }
    }

    &__images-wrapper {
        margin-bottom: 1.875rem;
    }

    &__add-images {
        display: flex;
        flex-wrap: wrap;
        gap: 1.875rem;
    }

    &__added-image-figure {
        position: relative;
        overflow: hidden;
        width: 17rem;
        padding-top: 17rem;
        border-radius: 0.25rem;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.25s var(--theme-main-bezier);

        &:hover,
        &:focus-within {
            box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);

            .add-property-form {
                &__added-image-name {
                    transform: translateY(0);
                }
            }
        }
    }

    &__added-image-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__delete-image {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        width: 2rem;
        height: 2rem;
        border-bottom-left-radius: 0.25rem;
        color: var(--white);
        font-size: 1.125rem;
        background-color: rgba(0, 0, 0, 0.25);
        transition: background-color 0.25rem var(--theme-main-bezier);

        &:hover,
        &:focus {
            background-color: var(--danger);
        }

        & > svg {
            pointer-events: none;
        }
    }

    &__added-image-name {
        z-index: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0.75rem 0.875rem;
        font-size: 0.875rem;
        line-height: 1;
        color: var(--white);
        background-color: rgba(0, 0, 0, 0.25);
        transform: translateY(100%);
        transition: transform 0.25s var(--theme-main-bezier);
    }

    &__add-images-input {
        position: absolute;
        top: 0;
        appearance: none;
        border: none;
        font-size: 0;
        padding: 0;
        width: 0;
        height: 0;
        opacity: 0;
    }

    &__add-images-label {
        position: relative;
        cursor: pointer;
        width: 17rem;
        padding-top: 17rem;
        border-radius: 0.25rem;
        border: 1px solid;
        border-color: var(--brand-mid-gray);
        background-position: center;
        background-size: 100%;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.25s var(--theme-main-bezier), border-color 0.25s var(--theme-main-bezier);

        &:hover,
        &:focus-within {
            border-color: var(--brand-cyan);
            box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
        }
    }

    &__contacts-top {
        display: flex;
        gap: 1.875rem;
        margin-bottom: 1.875rem;
        flex-wrap: wrap;

        & > input,
        & > div {
            flex-grow: 1;
            flex-basis: calc(25% - 1.875rem);
        }
    }

    &__contacts-bottom {
        display: flex;
        gap: 1.875rem;
        flex-wrap: wrap;

        & > textarea {
            flex-basis: calc(75% - (1.875rem / 4));
        }
    }

    &__contacts-consent-send {
        flex-basis: calc(25% - 1.5rem);

        & > .btn {
            margin-top: 1.875rem;
        }
    }
}

@media screen and (max-width: 1619px) {
    .add-property-form {
        &__add-images-label,
        &__added-image-figure {
            width: 15.5rem;
            padding-top: 15.5rem;
        }
    }
}

@media screen and (max-width: 1239px) {
    .add-property-form {
        &__heading > small {
            display: block;
            margin-top: 0.25rem;
            padding-left: 0;
        }

        &__add-images-label,
        &__added-image-figure {
            width: 16.5rem;
            padding-top: 16.5rem;
        }

        &__contacts {
            &-top {
                & > input,
                & > div {
                    flex-basis: calc(50% - (1.875rem / 2));
                }
            }

            &-bottom {
                & > textarea {
                    flex-basis: calc(50% - (1.875rem / 2));
                }
            }

            &-consent-send {
                flex-basis: calc(50% - (1.875rem / 2));
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .add-property-form {
        &__add-images-label,
        &__added-image-figure {
            width: 18rem;
            padding-top: 18rem;
        }

        &__contacts {
            &-top {
                flex-wrap: wrap;

                & > input,
                & > div {
                    flex-basis: calc(50% - (1.875rem / 2));
                }
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .add-property-form {
        &__add-images-label,
        &__added-image-figure {
            width: 13rem;
            padding-top: 13rem;
        }

        &__contacts {
            &-top {
                flex-wrap: wrap;
                gap: 1rem;

                & > input,
                & > div {
                    flex-basis: 100%;
                }
            }

            &-bottom {
                flex-wrap: wrap;
                gap: 1rem;

                & > textarea {
                    flex-basis: 100%;
                }
            }

            &-consent-send {
                flex-basis: 100%;
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .add-property-form {
        &__form {
            padding: 1rem;
        }

        &__add-images {
            gap: 1rem;
        }

        &__add-images-label,
        &__added-image-figure {
            width: 100%;
            padding-top: 14rem;
        }

        &__add-images-label {
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}
