.custom-checkbox {
    cursor: pointer;
    display: flex;
    font-family: 'Rubik', Arial, sans-serif;

    &.selected {
        .custom-checkbox {
            &__check {
                border-color: var(--brand-cyan);
                background-color: var(--brand-cyan);

                & > svg {
                    opacity: 1;
                    transform: translateX(-50%) translateY(-50%) scale(1);
                }
            }

            &__title {
                color: var(--brand-cyan);
            }
        }
    }

    &__check {
        position: relative;
        display: inline-flex;
        flex-shrink: 0;
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 1px;
        margin-right: 0.5rem;
        border-radius: 0.25rem;
        border: 1px solid;
        border-color: var(--brand-gray);
        font-size: 0.75rem;
        background-color: var(--brand-light-gray);
        transition: background-color 0.25s var(--theme-main-bezier), border-color 0.25s var(--theme-main-bezier);

        & > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            opacity: 0;
            color: var(--white);
            transform: translateX(-50%) translateY(-50%) scale(2);
            transition: transform 0.25s var(--theme-main-bezier);
        }
    }

    &__title {
        user-select: none;
        text-align: left;
        line-height: 1.25;
        color: var(--brand-light-purple);
        transition: color 0.25s var(--theme-main-bezier);

        & > a {
            color: var(--brand-cyan);

            &:hover {
                color: var(--brand-orange);
            }
        }
    }
}
