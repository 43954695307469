.ul-clean {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
}

.color {
    &-cyan {
        color: var(--brand-cyan);
    }
    &-orange {
        color: var(--brand-orange);
    }
    &-green {
        color: var(--green);
    }
    &-red {
        color: var(--red);
    }
}

.entry-content {
    h1 {
        font-size: 2.5rem;
    }

    & > br:last-child {
        display: none;
    }

    a {
        color: var(--brand-cyan);

        &:hover {
            color: var(--brand-orange);
        }
    }

    img {
        cursor: pointer;
    }
}

.advantages-list {
    &__col {
        &:not(:last-child) {
            padding-right: 1.875rem;
            border-right: 1px solid var(--brand-mid-gray);

            & + .advantages-list__col {
                padding-left: 1.875rem;
            }
        }

        &--positive {
            .advantages-list {
                &__heading,
                &__icon {
                    color: var(--green);
                }
            }
        }

        &--negative {
            .advantages-list {
                &__heading,
                &__icon {
                    color: var(--red);
                }
            }
        }
    }

    &__heading {
        padding-bottom: 1rem;
        margin-bottom: 1.875rem;
        border-bottom: 1px solid var(--brand-mid-gray);
    }

    &__item {
        & > h3 {
            display: flex;
            align-items: center;

            & > span {
                display: inline-flex;
                margin-right: 0.5rem;
                font-size: 1.125rem;
            }
        }
    }

    &__icon {
        display: inline-flex;
        align-items: center;
        margin-right: 0.5rem;
        font-size: 0.875rem;
    }
}

.block-heading {
    padding: 1.5rem 1.875rem;
    margin-bottom: 1.875rem;
    border-radius: 0.5rem;
    border: 1px solid var(--brand-gray);
    background-color: var(--white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

    &--red {
        color: var(--white);
        background-color: var(--red);
    }

    &--green {
        color: var(--white);
        background-color: var(--green);
    }

    &--orange {
        color: var(--white);
        background-color: var(--brand-orange);
    }

    &--cyan {
        color: var(--white);
        background-color: var(--brand-cyan);
    }
}

@media screen and (max-width: 767.98px) {
    .advantages-list {
        &__col {
            &:not(:last-child) {
                padding-right: 1rem;
                border-right: none;

                & + .advantages-list__col {
                    padding-left: 1rem;
                }
            }
        }
    }
}
