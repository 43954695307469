.service-page-card {
    padding: 1.875rem;
    border-radius: 0.5rem;
    border: 1px solid var(--brand-gray);
    color: var(--brand-dark-purple);
    font-family: 'Rubik', Arial, sans-serif;
    background-color: var(--white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

    &__title {
        margin-bottom: 1rem;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1;
    }

    &__description {
        margin-bottom: 1.875rem;
    }

}

@media screen and (max-width: 575.98px) {
    .service-page-card {
        padding: 1rem;
    }
}