.video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: (9 / 16) * 100%;
    background-color: var(--black);

    .react-player {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &__preview {
            color: var(--white);

            &:hover {
                & > svg {
                    transform: scale(1.125);
                }
            }

            & > svg {
                padding: 22.5%;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.25);
                transform: scale(1);
                transition: transform 0.25s var(--theme-main-bezier);
                filter: drop-shadow(0 0 2rem rgba(0, 0, 0, 0.5));
            }
        }
    }
}
