.nav-dropdown {
    position: relative;
    font-family: 'Rubik', Arial, sans-serif;
    transition: box-shadow 0.25s var(--theme-main-bezier);

    &.open {
        z-index: 100;

        & > .nav-dropdown {
            &__main {
                & > .nav-dropdown__icon {
                    transform: rotate(180deg) translateY(1px);
                }
            }

            &__dropdown {
                visibility: visible;
                opacity: 1;
                height: auto;
                transform: translateY(0);
            }
        }
    }
    
    &__main {
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        color: var(--white);
        line-height: 1;

        & > .nav__link {
            padding: 0.9375rem 1.625rem 0.9375rem 0.875rem;
        }
    }

    &__title {
        display: inline-flex;
        user-select: none;
        font-size: 1.125rem;
        padding: 0.9375rem 1.625rem 0.9375rem 0.875rem;
    }

    &__icon {
        pointer-events: none;
        position: absolute;
        right: 0.5rem;
        display: inline-flex;
        font-size: 1rem;
        transform: rotate(0) translateY(0);
    }

    &__dropdown {
        z-index: 0;
        overflow: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        opacity: 0;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        height: 0;
        min-width: 100%;
        border-radius: 0.25rem;
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
        background-color: var(--white);
        transform: translateY(-1.5rem);
        will-change: transform, opacity;
        transition: transform 0.25s var(--theme-main-bezier), opacity 0.25s var(--theme-main-bezier);

        & > .nav__link {
            padding: 0.625rem 0.875rem;
            color: var(--brand-dark-purple);
            font-size: 1rem;
            text-shadow: none;

            &:not(:last-child) {
                border-bottom: 1px solid var(--brand-gray);
            }

            &.current,
            &:hover,
            &:focus {
                color: var(--white);
                border-color: var(--brand-orange);
                background-color: var(--brand-orange);
            }
        }
    }
}