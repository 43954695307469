.newsletter {
    display: flex;
    flex-wrap: wrap;

    &__input {
        margin-right: 1rem;
    }

    &__consent {
        flex-basis: 100%;
        margin-top: 1rem;

        .custom-checkbox,
        .custom-checkbox__title {
            color: inherit;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .ReactModal__Content {
        .newsletter {
            flex-direction: column;

            &__input {
                margin-right: 0;
                margin-bottom: 1rem;
            }

            &__btn {
                justify-content: center;
            }
        }
    }
}
