.not-found {
    text-align: center;
    background-color: var(--brand-light-gray);

    &__section {
        display: flex;
        align-items: center;
        height: 100vh;
        color: var(--white);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__graphic {
        font-weight: 400;
        font-size: 13rem;
        line-height: 0.75;
    }

    &__heading {
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        font-family: 'Rubik', Arial, sans-serif;
        font-size: 2.5rem;
        line-height: 1;
        font-weight: 500;
    }

    .inner-page-top {
        height: 100px;

        &::before,
        &::after {
            height: 100%;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .not-found {
        &__section {
            padding-top: 8rem;
            padding-bottom: 8rem;
            height: auto;
        }

        &__graphic {
            font-size: 8rem;
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}