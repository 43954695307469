.home {
    & > section:nth-child(even) {
        background-color: var(--brand-light-gray);
        .slick-slider {
            &::before {
                background: linear-gradient(90deg, var(--brand-light-gray), transparent);
            }

            &::after {
                background: linear-gradient(-90deg, var(--brand-light-gray), transparent);
            }
        }
    }

    & > section {
        background-color: var(--white);
        .slick-slider {
            &::before {
                background: linear-gradient(90deg, var(--white), transparent);
            }

            &::after {
                background: linear-gradient(-90deg, var(--white), transparent);
            }
        }
    }

    & > .search-bar {
        background-color: var(--white) !important;
    }
}
