.form-dropdown {
    position: relative;
    font-family: 'Rubik', Arial, sans-serif;
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0);
    transition: box-shadow 0.25s var(--theme-main-bezier);

    &:hover:not(.open) {
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

        .form-dropdown {
            &__toggler {
                border-color: var(--brand-cyan);
            }
        }
    }

    &.open {
        z-index: 100;
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

        & > .form-dropdown {
            &__toggler {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                & > .form-dropdown__icon {
                    transform: rotate(180deg) translateY(1px);
                }
            }

            &__dropdown {
                visibility: visible;
                opacity: 1;
                height: auto;
                transform: translateY(0);
            }
        }
    }

    &.two-column-checkbox {
        &.open {
            & > .form-dropdown {
                &__toggler {
                    padding-bottom: 1.4375rem;
                    margin-bottom: -0.5rem;
                }
            }
        }

        & > .form-dropdown {
            &__dropdown {
                overflow-y: unset;
                top: calc(100% + 0.5rem - 1px);
                left: auto;
                display: flex;
                flex-wrap: wrap;
                gap: 0.625rem;
                width: calc(200% + 1rem);
                border-top: 1px solid;
                border-color: var(--brand-gray);
                border-top-left-radius: 0.25rem;

                & > .form-dropdown {
                    flex-basis: calc(50% - 0.625rem);
                }
            }
        }
    }

    &__toggler {
        z-index: 1;
        position: relative;
        display: flex;
        width: 100%;
        padding: 0.9375rem 2.5rem 0.9375rem 0.9375rem;
        line-height: 1;
        border-radius: 0.25rem;
        border: 1px solid;
        border-color: var(--brand-gray);
        background-color: var(--white);
    }

    &__title {
        display: inline-flex;
        user-select: none;
    }

    &__icon {
        position: absolute;
        right: 0.9375rem;
        transform: rotate(0) translateY(0);
    }

    &__dropdown {
        overflow-y: auto;
        z-index: 0;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        height: 0;
        max-height: 22.5rem;
        padding: 0.625rem 0.9375rem;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-right: 1px solid;
        border-bottom: 1px solid;
        border-left: 1px solid;
        border-color: var(--brand-gray);
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
        background-color: var(--white);
        transform: translateY(-1.5rem);
        will-change: transform, opacity;
        transition: transform 0.25s var(--theme-main-bezier), opacity 0.25s var(--theme-main-bezier);
    }

    &__option {
        .custom-radio,
        .custom-checkbox {
            width: 100%;
            padding: 0.3125rem 0;
        }
    }

    &__selection {
        pointer-events: none;
        z-index: 2;
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        bottom: 0.5rem;
        display: flex;
        align-items: center;
        max-width: calc(100% - 2.5rem);
        padding: 0 0.5rem;
        border-radius: 0.25rem;
        line-height: 1;
        background-color: var(--white);

        &-text {
            pointer-events: none;
            margin-right: 0.5rem;
        }

        &-delete {
            cursor: pointer;
            pointer-events: all;
            color: var(--brand-mid-gray);
            
            &:hover {
                color: var(--red);
            }
        }
    }
}
