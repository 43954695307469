.icon-feature {
    display: flex;
    flex-direction: column;
    font-family: 'Rubik', Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1;

    &:not(:last-child) {
        margin-right: 1.25rem;
    }

    &__name {
        margin-bottom: 0.5rem;
    }

    &__icon {
        font-size: 1.125rem;
        margin-right: 0.5rem;
        color: var(--brand-mid-gray);
    }

    &__icon-value {
        display: flex;
        align-items: center;
    }
}