.property-extras {
    display: flex;
    color: var(--white);
    font-family: 'Rubik', Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.2;

    &__item {
        padding: 0.25rem 0.625rem;
        border-radius: 10rem;

        &.top-offer {
            background-color: var(--brand-orange);
        }

        &.new-offer {
            background-color: var(--red);
        }

        &.discount {
            background-color: var(--green);
        }
    }
}
