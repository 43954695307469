.custom-radio {
    cursor: pointer;
    user-select: none;
    font-family: 'Rubik', Arial, sans-serif;

    &:not(&--button) {
        display: flex;

        &.selected {
            .custom-radio {
                &__check {
                    border-color: var(--brand-cyan);
                    background-color: var(--brand-cyan);

                    &::before {
                        opacity: 1;
                        transform: translateX(-50%) translateY(-50%) scale(1);
                    }
                }

                &__title {
                    color: var(--brand-cyan);
                }
            }
        }

        .custom-radio {
            &__check {
                position: relative;
                display: inline-flex;
                flex-shrink: 0;
                width: 1.125rem;
                height: 1.125rem;
                margin-top: 1px;
                margin-right: 0.5rem;
                border-radius: 10rem;
                border: 1px solid;
                border-color: var(--brand-gray);
                background-color: var(--brand-light-gray);
                transition: background-color 0.25s var(--theme-main-bezier), border-color 0.25s var(--theme-main-bezier);

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 0.5rem;
                    height: 0.5rem;
                    opacity: 0;
                    border-radius: 10rem;
                    background-color: var(--white);
                    transform: translateX(-50%) translateY(-50%) scale(0);
                    transition: transform 0.25s var(--theme-main-bezier);
                }
            }

            &__title {
                user-select: none;
                text-align: left;
                line-height: 1.25;
                color: var(--brand-light-purple);
                transition: color 0.25s var(--theme-main-bezier);
            }
        }
    }

    &--button {
        overflow: hidden;
        display: inline-flex;
        padding: 0.9375rem;
        border-radius: 0.25rem;
        border: 1px solid;
        border-color: var(--brand-gray);
        line-height: 1;
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0);
        transition: box-shadow 0.25s var(--theme-main-bezier), border-color 0.25s var(--theme-main-bezier);

        &:hover {
            border-color: var(--brand-cyan);
            box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
        }

        &.selected {
            color: var(--white);
            background-color: var(--brand-cyan);
        }

        &:not(:last-child) {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            & + .custom-radio {
                &--button {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            &:hover {
                & + .custom-radio {
                    &--button {
                        border-left-color: var(--brand-cyan);
                    }
                }
            }
        }
    }
}
