.sitemap {
    ul {
        li {
            .link {
                &:hover {
                    color: var(--brand-orange);
                }
            }
        }
    }
}