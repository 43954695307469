.inner-page-top {
    padding-top: 7.25rem;
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: fixed;

    &::before {
        content: '';
        z-index: 0;
        position: absolute;
        right: 0;
        left: 0;
    }

    &::before {
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    &__content {
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        padding-bottom: 2rem;
        color: var(--white);
        font-family: 'Rubik', Arial, sans-serif;
    }

    &__heading,
    &__description {
        margin-bottom: 0;
    }

    &__description {
        font-size: 1rem;
    }
}
