@charset "UTF-8";

$rt-namespace: 'Toastify';
$rt-toast-width: 367px !default;
$rt-toast-background: #fff !default;
$rt-toast-min-height: 64px !default;
$rt-toast-max-height: 800px !default;

$rt-color-default: #fff !default;
$rt-color-dark: var(--brand-dark-purple) !default;
$rt-color-info: var(--brand-cyan) !default;
$rt-color-success: var(--success) !default;
$rt-color-warning: var(--brand-orange-dark) !default;
$rt-color-error: var(--danger) !default;

$rt-text-color-default: var(--brand-dark-purple) !default;
$rt-text-color-dark: #fff !default;

$rt-color-progress-default: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55) !default;
$rt-color-progress-dark: #bb86fc !default;
$rt-mobile: 'only screen and (max-width : 480px)' !default;
$rt-font-family: 'Rubik', Arial, sans-serif !default;
$rt-z-index: 9999 !default;

@import '~react-toastify/scss/variables';
@import '~react-toastify/scss/toastContainer';
@import '~react-toastify/scss/toast';
@import '~react-toastify/scss/closeButton';
@import '~react-toastify/scss/progressBar';

// entrance and exit animations
@import '~react-toastify/scss/animations/bounce.scss';
@import '~react-toastify/scss/animations/zoom.scss';
@import '~react-toastify/scss/animations/flip.scss';
@import '~react-toastify/scss/animations/slide.scss';

// Styles Overwrite
.Toastify {
    &__toast {
        padding: 1.875rem;
        border-radius: 0.5rem;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);

        &--default {
            .Toastify {
                &__close-button {
                    background-color: var(--danger);
                }

                &__progress-bar {
                    background-color: rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    &__toast-body {
        z-index: 1;
        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &__close-button {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.25rem;
        width: 1.875rem;
        height: 1.875rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        opacity: 1;
        color: var(--white);
        font-size: 1.125rem;
        line-height: 1;
        background-color: rgba(0, 0, 0, 0.2);

        &:hover {
            & > svg {
                opacity: 0.75;
            }
        }

        & > svg {
            width: auto;
            height: auto;
            opacity: 1;
            transition: transform 0.25s var(--theme-main-bezier);
        }
    }

    &__progress-bar {
        z-index: 0;
        height: 0.25rem;
        background-color: white;
    }
}
