.btn {
    display: inline-flex;
    align-items: center;
    padding: 0.9375rem 1.875rem;
    border-radius: 0.25rem;
    color: var(--white);
    font-family: 'Rubik', Arial, sans-serif;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1;
    text-transform: capitalize;
    transition: all 0.2s var(--theme-main-bezier);

    &:hover {
        & > svg {
            transform: scale(1.25) translateX(-0.25rem);
        }
    }

    & > svg {
        font-size: 1rem;
        margin-right: 0.625rem;
        transform: scale(1) translateX(0);
        transition: transform 0.25s var(--theme-main-bezier);
    }

    &--compact {
        padding: 0.5rem 0.875rem;
    }

    &--cyan {
        background-color: var(--brand-cyan);

        &:hover {
            color: var(--white);
            background-color: var(--brand-cyan-dark);
        }
    }

    &--orange {
        background-color: var(--brand-orange);

        &:hover {
            color: var(--white);
            background-color: var(--brand-orange-dark);
        }
    }
    
    &--green {
        background-color: #28a745;

        &:hover {
            color: var(--white);
            background-color: #1e973a;
        }
    }
    
    &--red {
        background-color: #dc3545;

        &:hover {
            color: var(--white);
            background-color: #cc2737;
        }
    }

    &--dark {
        background-color: var(--brand-light-purple);

        &:hover {
            color: var(--white);
            background-color: var(--brand-dark-purple);
        }
    }
}

@media screen and (max-width: 575.98px) {
    .btn {
        padding: 0.9375rem 1.25rem;
        font-size: 1rem;
    }
}