.no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;

    &__icon {
        color: var(--red);
        font-size: 1.25rem;
        margin-right: 0.5rem;
    }

    &__text {
        font-size: 1.125rem;
    }
}