.property-card {
    overflow: hidden;
    height: 100%;
    border-radius: 0.5rem;
    color: var(--brand-dark-purple);
    background-color: var(--white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.25s var(--theme-main-bezier);

    &:hover {
        color: var(--brand-dark-purple);
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);

        .property-card {
            &__image {
                transform: scale(1.1);
            }

            &__title,
            &__icon-seen {
                color: var(--brand-orange);
            }
        }

        .property-extras {
            &__item {
                transform: translateX(-100%);
            }
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__figure {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: (4 / 5) * 100%;

        &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 4rem;
            background: linear-gradient(0deg, rgba(black, 0.25), transparent);
        }
    }

    .property-extras {
        z-index: 1;
        position: absolute;
        top: 1.25rem;
        flex-direction: column;
        align-items: flex-start;

        &__item {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            transform: translateX(0);
            transition: transform 0.25s var(--theme-main-bezier);

            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: transform 0.25s var(--theme-main-bezier);
    }

    &__info {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 1.25rem 1.5rem;
    }

    &__title {
        margin-bottom: 0.5rem;
        font-family: 'Rubik', Arial, sans-serif;
        font-size: 1.25rem;
        line-height: 1.2;
        font-weight: 500;
        color: var(--brand-dark-purple);
        transition: color 0.25s var(--theme-main-bezier);
    }

    &__description {
        font-size: 0.875rem;
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    &__features {
        display: flex;
        margin-bottom: 1.25rem;
    }

    &__details {
        display: flex;
        flex-direction: column;
        padding-top: 1.25rem;
        margin-top: auto;
        border-top: 1px solid var(--brand-mid-gray);
        font-family: 'Rubik', Arial, sans-serif;
        line-height: 1;

        &-top,
        &-bottom {
            display: flex;
            justify-content: space-between;
        }

        &-top {
            margin-bottom: 0.5rem;
            gap: 1rem;
        }
    }

    &__type {
        white-space: nowrap;
    }

    &__designations {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__icon {
        &-wrapper {
            display: flex;
            align-items: center;
        }

        &-seen {
            display: flex;
            justify-content: flex-end;
            font-size: 1.5rem;
            color: var(--brand-mid-gray);
            transition: color 0.25s var(--theme-main-bezier);

            &.seen {
                color: var(--brand-dark-purple);
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .property-card {
        &__info {
            padding: 1rem;
        }
    }
}