// Gray Shades
// ------------------------------------->
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

// Base Colors
// ------------------------------------->
$blue:      #007bff !default;
$indigo:    #6610f2 !default;
$purple:    #6f42c1 !default;
$pink:      #e83e8c !default;
$red:       #dc3545 !default;
$orange:    #fd7e14 !default;
$yellow:    #ffc107 !default;
$green:     #28a745 !default;
$teal:      #20c997 !default;
$cyan:      #17a2b8 !default;

// Brand Colors
// ------------------------------------->
$brand-orange:        #F49B19 !default;
$brand-orange-dark:   #D6850A !default;
$brand-cyan:          #17A2B8 !default;
$brand-cyan-dark:     #1490A3 !default;
$brand-light-gray:    #F8F9FA !default;
$brand-gray:          #D9D9D9 !default;
$brand-mid-gray:      #CAC7CB !default;
$brand-dark-gray:     #968E97 !default;
$brand-light-purple:  #413443 !default;
$brand-dark-purple:   #2D1E2F !default;
                    

$colors: () !default;
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "black":      $black,
    "gray":       $gray-600,
    "gray-dark":  $gray-800,
  ),
  $colors
);

// Theme Colors
// ------------------------------------->
$primary:             $blue !default;
$secondary:           $gray-600 !default;
$success:             $green !default;
$info:                $cyan !default;
$warning:             $yellow !default;
$danger:              $red !default;
$light:               $gray-100 !default;
$dark:                $gray-800 !default;


$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":              $primary,
    "secondary":            $secondary,
    "success":              $success,
    "info":                 $info,
    "warning":              $warning,
    "danger":               $danger,
    "light":                $light,
    "dark":                 $dark,
    "brand-orange":         $brand-orange,
    "brand-orange-dark":    $brand-orange-dark,
    "brand-cyan":           $brand-cyan,
    "brand-cyan-dark":      $brand-cyan-dark,
    "brand-light-gray":     $brand-light-gray,
    "brand-gray":           $brand-gray,
    "brand-mid-gray":       $brand-mid-gray,
    "brand-dark-gray":      $brand-dark-gray,
    "brand-light-purple":   $brand-light-purple,
    "brand-dark-purple":    $brand-dark-purple,
  ),
  $theme-colors
);

// Grid Breakpoints
// ------------------------------------->
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1240px,
    xxl: 1620px,
) !default;

// Container Max Width
// ------------------------------------->
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px,
    xxl: 1590px,
) !default;

// Heading Font-size
// ------------------------------------->

$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1rem;
$h6-font-size: 0.875rem;

// Spacing
// ------------------------------------->
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .5),
    2:  $spacer,
    3: ($spacer * 1.5),
    4: ($spacer * 2),
    5: ($spacer * 2.5),
    6: ($spacer * 3)
  ),
  $spacers
);

// Grid columns
// ------------------------------------->
$grid-columns:                12 !default;
$grid-gutter-width:           2rem;
$grid-row-columns:            6 !default;

// Options
// ------------------------------------->
$enable-prefers-reduced-motion-media-query:   false;
$input-focus-box-shadow:                      none;
$btn-box-shadow:                              none;
$btn-focus-box-shadow:                        none;
$btn-active-box-shadow:                       none;

// Bootstrap Components
// ------------------------------------->
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
// @import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
// @import "~bootstrap/scss/custom-forms";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";


