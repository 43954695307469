.property {
    background-color: var(--brand-light-gray);

    &__price {
        padding-left: 1.875rem;
        border-left: 1px solid var(--brand-mid-gray);
        font-family: 'Rubik', Arial, sans-serif;

        &-type {
            font-weight: 500;
            line-height: 1;
            margin-bottom: 0.5rem;
        }

        .property-price {
            &__current {
                font-size: 2rem;
            }

            &__old {
                font-size: 1.5rem;
            }
        }
    }

    &__details {
        font-family: 'Rubik', Arial, sans-serif;
        padding: 1.875rem;
        border-radius: 0.5rem;
        border: 1px solid var(--brand-gray);
        background-color: var(--white);
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

        &-heading {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;

            & > li {
                flex-basis: 50%;
                padding-right: 1rem;
                line-height: 1.5;

                & > span {
                    font-weight: 500;
                }
            }
        }
    }

    &__description {
        &-heading {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        &-paragraph {
            font-size: 1rem;
        }
    }

    .loading {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 100px;
            background-color: var(--brand-dark-purple);
        }

        &__col {
            height: 100vh;
            justify-content: center;

            & > svg {
                margin: 0 !important;
            }
        }
    }
}

@media screen and (max-width: 1239px) {
    .property {
        .property-extras {
            margin-bottom: 1rem;

            &__item:not(:last-child) {
                margin-right: 0.5rem;
            }
        }

        &__info {
            display: flex;
            gap: 1.875rem;
        }

        &__heading {
            flex-grow: 1;
        }

        &__price {
            min-width: 25%;
        }

        &__title {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        &__other {
            display: flex;
        }
    }
}

@media screen and (max-width: 991px) {
    .property {
        &__details-list {
            & > li {
                flex-basis: 100%;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .property {
        &__info {
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
        }

        &__other,
        .property-extras,
        .property-price {
            justify-content: center;
        }

        &__title {
            margin-bottom: 0.5rem;
        }

        &__heading,
        &__price {
            flex-basis: 100%;
        }

        &__price {
            padding-left: 0;
            border-left: none;
        }

        .inner-page-content {
            padding-top: 1.875rem;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .property {
        &__details {
            padding: 1rem;
        }

        &__other {
            flex-direction: column;
        }
    }
}