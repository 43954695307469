@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site //jonsuh.com/hamburgers
 * @link //github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x           : 15px !default;
$hamburger-padding-y           : 15px !default;
$hamburger-layer-width         : 40px !default;
$hamburger-layer-height        : 4px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : #fff !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity       : 1 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
//   3dx,
//   3dx-r,
//   3dy,
//   3dy-r,
//   3dxy,
//   3dxy-r,
//   arrow,
//   arrow-r,
//   arrowalt,
//   arrowalt-r,
//   arrowturn,
//   arrowturn-r,
//   boring,
//   collapse,
//   collapse-r,
//   elastic,
//   elastic-r,
//   emphatic,
//   emphatic-r,
//   minus,
//   slider,
//   slider-r,
//   spin,
//   spin-r,
//   spring,
//   spring-r,
//   stand,
//   stand-r,
  squeeze,
//   vortex,
//   vortex-r
) !default;

// Base Hamburger (We need this)
// ==================================================
@import "~hamburgers/_sass/hamburgers/base";

// Hamburger types
// ==================================================
// @import "~hamburgers/_sass/hamburgers/types/3dx";
// @import "~hamburgers/_sass/hamburgers/types/3dx-r";
// @import "~hamburgers/_sass/hamburgers/types/3dy";
// @import "~hamburgers/_sass/hamburgers/types/3dy-r";
// @import "~hamburgers/_sass/hamburgers/types/3dxy";
// @import "~hamburgers/_sass/hamburgers/types/3dxy-r";
// @import "~hamburgers/_sass/hamburgers/types/arrow";
// @import "~hamburgers/_sass/hamburgers/types/arrow-r";
// @import "~hamburgers/_sass/hamburgers/types/arrowalt";
// @import "~hamburgers/_sass/hamburgers/types/arrowalt-r";
// @import "~hamburgers/_sass/hamburgers/types/arrowturn";
// @import "~hamburgers/_sass/hamburgers/types/arrowturn-r";
// @import "~hamburgers/_sass/hamburgers/types/boring";
// @import "~hamburgers/_sass/hamburgers/types/collapse";
// @import "~hamburgers/_sass/hamburgers/types/collapse-r";
// @import "~hamburgers/_sass/hamburgers/types/elastic";
// @import "~hamburgers/_sass/hamburgers/types/elastic-r";
// @import "~hamburgers/_sass/hamburgers/types/emphatic";
// @import "~hamburgers/_sass/hamburgers/types/emphatic-r";
// @import "~hamburgers/_sass/hamburgers/types/minus";
// @import "~hamburgers/_sass/hamburgers/types/slider";
// @import "~hamburgers/_sass/hamburgers/types/slider-r";
// @import "~hamburgers/_sass/hamburgers/types/spin";
// @import "~hamburgers/_sass/hamburgers/types/spin-r";
// @import "~hamburgers/_sass/hamburgers/types/spring";
// @import "~hamburgers/_sass/hamburgers/types/spring-r";
// @import "~hamburgers/_sass/hamburgers/types/stand";
// @import "~hamburgers/_sass/hamburgers/types/stand-r";
@import "~hamburgers/_sass/hamburgers/types/squeeze";
// @import "~hamburgers/_sass/hamburgers/types/vortex";
// @import "~hamburgers/_sass/hamburgers/types/vortex-r";

// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }
