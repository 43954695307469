.nav {
    &-wrapper {
        display: inline-flex !important;
    }

    &__link {
        display: inline-flex;
        padding: 0.9375rem 0.875rem;
        color: var(--white);
        font-family: 'Rubik', Arial, sans-serif;
        font-size: 1.125rem;
        line-height: 1;
        white-space: nowrap;
        text-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);

        &.current,
        &:hover,
        &:focus {
            color: var(--yellow);
        }
    }

    &--tablet {
        .nav {
            &-overlay {
                z-index: 0;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;
                background-color: rgba(0, 0, 0, 0.5);
            }

            &-wrapper {
                pointer-events: none;
                overflow-y: auto;
                z-index: 1;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                display: flex !important;
                align-items: center;
                flex-direction: column;
                visibility: hidden;
                opacity: 0;
                padding-top: 100px;
                min-width: 50vw;
                background-color: var(--brand-dark-purple);

                & > .nav {
                    &__link {
                        padding: 0.9375rem 1.25rem;
                    }

                    &__link,
                    &-dropdown {
                        width: 100%;
                        background-color: rgba(255, 255, 255, 0.1);

                        &:not(:last-child) {
                            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                        }
                    }
                }
            }

            &__link {
                display: flex;
            }

            &-dropdown {
                width: 100%;

                &__title,
                &__main > .nav__link {
                    padding: 0.9375rem 2rem 0.9375rem 1.25rem;
                }

                &__icon {
                    right: 1.25rem;
                    font-size: 1.25rem;
                }

                &__dropdown {
                    position: relative;
                    top: auto;
                    left: auto;
                    align-items: center;
                    background-color: rgba(255, 255, 255, 0.1);
                    transform: translateY(0);
                    border-radius: 0;

                    & > .nav__link {
                        width: 100%;
                        padding: 0.75rem 1.25rem;
                        font-size: 0.875rem;
                        color: var(--white);
                        border-color: rgba(255, 255, 255, 0.2);
                    }
                }
            }
        }

        &.visible {
            .nav {
                &-overlay,
                &-wrapper {
                    pointer-events: all;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    .hamburger {
        position: relative;
        z-index: 1;
        padding: 0.875rem 0 0.875rem 1.5rem;
        display: inline-flex;
    }
}

@media screen and (max-width: 991px) {
    .nav {
        &__social {
            font-size: 1.25rem;

            & > .social__link:not(:last-child) {
                margin-right: 1.25rem;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .nav {
        &--tablet .nav {
            &-wrapper {
                padding-top: 90px;
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .nav {
        &--tablet {
            .nav {
                &-overlay {
                    display: none;
                }

                &-wrapper {
                    width: 100%;
                }
            }

            .nav-dropdown__dropdown > .nav__link {
                padding: 0.9375rem 1.25rem;
            }
        }
    }
}
