.promo-service-pack {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.875rem;
    border-radius: 0.5rem;
    border: 1px solid var(--brand-gray);
    color: var(--brand-dark-purple);
    font-family: 'Rubik', Arial, sans-serif;
    text-align: center;
    background-color: var(--white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

    &__icon {
        margin-bottom: 1.5rem;
        color: var(--brand-mid-gray);
        font-size: 3.5rem;
        line-height: 1;
    }

    &__sub-title {
        color: var(--brand-dark-gray);
    }

    &__items {
        padding-top: 1rem;
        margin-bottom: 1rem !important;
        border-top: 1px solid var(--brand-gray);
        color: var(--green);
        font-weight: 500;
    }

    &__disclaimer {
        margin-top: 1rem;
        color: var(--brand-dark-gray);
        font-size: 0.875rem;
        line-height: 1.25;
    }

    &__button {
        display: flex;
        justify-content: center;
        margin-top: auto;

        & > span {
            font-size: 2rem;
        }

        &.btn--compact {
            justify-content: space-between;
        }
    }
}
