.last-seen-properties {
    padding: 2rem 0 4rem;
    background-color: var(--white);

    .property-card {
        &__figure {
            padding-top: (8 / 15) * 100%;
        }

        &__features {
            display: none;
        }
    }
}