.hero {
    z-index: 1;
    height: calc(100vh - 4.375rem);
    max-height: 970px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;

    &__caption {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 6.25rem;
        color: var(--white);
        font-family: 'Rubik', Arial, sans-serif;

        &-title {
            font-size: 3rem;
            text-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
        }

        &-description {
            margin-bottom: 1rem;
            text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
        }

        &-buttons {
            display: flex;
        }
    }

    &__featured {
        position: relative;
        width: 100%;
        padding-bottom: 3rem;
        margin-bottom: 6.25rem;

        &-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .slick-slider {
            position: static;
        }

        .slick-slide {
            opacity: 0;
            transform: scale(0.95);
            transition: opacity 0.5s var(--theme-main-bezier) 0.25s, transform 0.5s var(--theme-main-bezier);

            &.slick-active {
                opacity: 1;
                transform: scale(1);
                transition: opacity 0.5s var(--theme-main-bezier), transform 0.5s var(--theme-main-bezier) 0.625s;
            }
        }

        .slick-arrow {
            top: auto;
            bottom: 0;
            padding: 0;
            color: var(--white);
            font-size: 1.125rem;
            transform: translateY(0);

            &.slick-prev {
                left: auto;
                right: 2.5rem;
            }

            &.slick-next {
                right: 0;
            }

            &.slick-disabled {
                opacity: 0.5;
            }
        }
    }

    &__gradient {
        &-top,
        &-right,
        &-bottom {
            z-index: 0;
            position: absolute;
            right: 0;
        }

        &-top {
            top: 0;
            left: 0;
            height: 8rem;
            background: linear-gradient(180deg, rgba(black, 0.66), transparent);
        }

        &-right {
            top: 0;
            bottom: 0;
            left: 50%;
            background: linear-gradient(-90deg, rgba(black, 0.66), transparent);
        }

        &-bottom {
            height: 20rem;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(black, 0.66), transparent);
        }
    }
}

@media screen and (max-width: 1619px) {
    .hero {
        background-size: cover;

        &__caption {
            padding-bottom: 3.5rem;
        }

        &__featured {
            margin-bottom: 3.5rem;

            .property-card {
                &__features {
                    display: none;
                }

                &__figure {
                    padding-top: 53.33333%;
                }
            }
        }
    }
}

@media screen and (max-width: 1239px) {
    .hero {
        height: calc(100vh - 100px);

        &__gradient-right {
            display: none;
        }

        &__caption {
            align-items: center;
            text-align: center;
            padding-bottom: 6.5rem;

            &-title {
                font-size: 2.5rem;
                line-height: 1;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .hero {
        height: calc(100vh - 132px);

        &__caption {
            align-items: center;
            text-align: center;
            padding-bottom: 8.5rem;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .hero {
        height: auto;

        &__caption {
            padding-top: 8.5rem;
            padding-bottom: calc(335px + 4rem);
        }

        &__caption-buttons {
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 463.98px) {
    .hero {
        &__caption {
            padding-top: 8.5rem;
            padding-bottom: calc(399px + 4rem);
        }
    }
}