.loading {
    padding: 0 0 3rem;

    &__col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__svg {
        width: 120px;
        height: 120px;
    }

    &__text {
        margin-left: 0.875rem;
        font-size: 1.125rem;
    }
}