.icon-step-card {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 0.5rem;
    border: 1px solid var(--brand-gray);
    font-family: 'Rubik', Arial, sans-serif;
    background-color: var(--white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

    &__number {
        position: absolute;
        top: 0;
        left: 0;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        color: var(--white);
        font-weight: 500;
        background-color: var(--brand-mid-gray);
    }

    &__icon-wrapper {
        display: flex;
        align-self: stretch;
        align-items: center;
        justify-content: center;
        min-width: 8rem;
        padding: 2rem 0;
        border-right: 1px solid var(--brand-gray);
        color: var(--brand-mid-gray);
        font-size: 3.5rem;

        & > .fa-file-signature {
            transform: translateX(0.625rem);
        }
    }

    &__content-wrapper {
        padding: 1.875rem;
    }

    &--wide {
        .icon-step-card {
            &__icon-wrapper {
                min-width: 10rem;
                font-size: 4rem;
            }
        }
    }

    &--green {
        .icon-step-card {
            &__number {
                background-color: var(--green);
            }

            &__icon-wrapper {
                color: var(--green);
            }
        }
    }

    &--orange {
        .icon-step-card {
            &__number {
                background-color: var(--brand-orange);
            }

            &__icon-wrapper {
                color: var(--brand-orange);
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .icon-step-card {
        &--wide {
            .icon-step-card {
                &__icon-wrapper {
                    min-width: 8rem;
                    font-size: 3.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .icon-step-card {
        flex-direction: column;

        &__icon-wrapper {
            border-right: none;
            border-bottom: 1px solid var(--brand-gray);
        }
    }
}
