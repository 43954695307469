.inquiry-form {
    padding: 1.875rem;
    border-radius: 0.5rem;
    border: 1px solid var(--brand-gray);
    background-color: var(--white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

    &.error-consent {
        .custom-checkbox {
            margin-bottom: 0.5rem;

            &__check {
                border-color: var(--red);
            }
        }
    }

    &__description {
        font-size: 1rem;
    }

    input,
    textarea {
        width: 100%;
        margin-bottom: 1rem;
    }

    .custom-checkbox {
        margin-bottom: 1rem;
    }

    &__error {
        margin-bottom: 1rem;
        color: var(--red);
        line-height: 1;
    }
}

.ReactModal__Content {
    .inquiry-form {
        padding: 0;
        border-radius: 0;
        border: none;
        background-color: var(--white);
        box-shadow: none;
    }
}

@media screen and (max-width: 575.98px) {
    .inquiry-form {
        padding: 1rem;
    }
}
