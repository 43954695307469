.property-gallery {
    height: 100vh;
    font-family: 'Rubik', arial, sans-serif;
    background-color: black;

    &::before,
    &::after {
        pointer-events: none;
        z-index: 1;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
    }

    &::before {
        top: 0;
        height: 8rem;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.66), transparent);
    }

    &::after {
        bottom: 0;
        height: 20rem;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.66), transparent);
    }

    &__main {
        position: relative;

        .property-gallery {
            &__figure {
                position: relative;
                overflow: hidden;
                width: 100%;
                padding-top: 100vh;
            }

            &__click-icon {
                pointer-events: none;
                z-index: 2;
                position: absolute;
                color: var(--white);
                top: 50%;
                left: 50%;
                opacity: 0;
                font-size: 3rem;
                transform: translate(-50%, -50%) scale(0);
                animation: pointer-icon 2s var(--theme-main-bezier) 1s forwards;
            }

            &__img {
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;

                &--background {
                    opacity: 0.75;
                    filter: blur(10px);
                }

                &--foreground {
                    object-fit: contain;
                    filter: drop-shadow(0 0 2rem rgba(0, 0, 0, 0.5));
                }
            }
        }

        .slick-slide {
            pointer-events: none;

            &.slick-active {
                pointer-events: all;
            }
        }
    }

    &__header {
        z-index: 2;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3.75rem;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: white;

        .property-extras {
            margin-bottom: 1rem;

            &__item {
                &:not(:last-child) {
                    margin-right: 0.5rem;
                }
            }
        }

        &-title {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        &-other {
            display: flex;
        }
    }

    &__nav {
        display: flex;
        justify-content: flex-end;

        &-inner {
            flex-grow: 1;
        }

        &-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;
            color: var(--white);
            font-weight: 500;
            font-size: 1.125rem;
        }

        &-button {
            & > svg {
                font-size: 1.25rem;
            }
        }

        &-index {
            line-height: 1;
        }

        .property-gallery {
            &__figure {
                position: relative;
                overflow: hidden;
                width: 100%;
                padding-top: 100%;
                border-radius: 0.25rem;
                background-color: var(--black);
                box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.25);
            }

            &__img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.75;
                transition: opacity 0.25s var(--theme-main-bezier);
            }
        }

        .slick-list {
            margin: -0.9375rem;
        }

        .slick-slide {
            cursor: pointer;
            padding: 0.9375rem;

            & > div {
                line-height: 0;
            }

            &:hover,
            &.slick-current {
                .property-gallery {
                    &__img {
                        opacity: 1;
                    }
                }
            }
        }

        .slick-arrow {
            pointer-events: none;
            top: 0.9325rem;
            bottom: 0.9325rem;
            display: flex !important;
            align-items: center;
            justify-content: center;
            padding: 0;
            border-radius: 0.25rem;
            width: 102px;
            color: var(--white);
            font-size: 1.5rem;
            transform: translateY(0);

            &.slick-prev {
                left: 0;
            }

            &.slick-next {
                right: 0;
            }
        }
    }
}

@keyframes pointer-icon {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
    }
    45% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(2);
    }
    50% {
        opacity: 0.5;
        transform: translate(-50%, -50%) scale(1);
    }
    60% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(2);
    }
    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
    }
}

@media screen and (max-width: 1239px) {
    .property-gallery {
        &__header {
            bottom: 2rem;
        }
    }
}

@media screen and (max-width: 991px) {
    .property-gallery {
        &__nav .slick-arrow {
            width: 90px;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .property-gallery {
        &:not(&--single) {
            height: calc(100vh - 8rem);
            margin-bottom: 8rem;

            .property-gallery {
                &::after {
                    height: 10rem;
                }

                &__main {
                    .property-gallery {
                        &__figure {
                            padding-top: calc(100vh - 8rem);
                        }
                    }
                }

                &__header {
                    bottom: -6rem;
                }

                &__nav .slick-arrow {
                    width: calc(33.33333% - (2rem / 2));

                    &.slick-next {
                        right: -2px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 463.98px) {
    .property-gallery {
        &:not(&--single) {
            height: calc(100vh - 5rem);
            margin-bottom: 5rem;

            .property-gallery {
                &__main {
                    .property-gallery {
                        &__figure {
                            padding-top: calc(100vh - 5rem);
                        }
                    }
                }

                &__header {
                    bottom: -3rem;
                }
            }
        }
    }
}
