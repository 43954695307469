.review-card {
    position: relative;
    height: 100%;
    padding: 1.875rem;
    border-radius: 0.5rem;
    border: 1px solid var(--brand-gray);
    text-align: center;
    font-family: 'Rubik', Arial, sans-serif;
    background-color: var(--white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-top-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        color: var(--white);
        background-color: var(--brand-orange);
    }

    &__author {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.25rem;
        margin-bottom: 1rem;
    }

    &__content {
        margin-bottom: 0;
        font-size: 1.125rem;

        &::before {
            content: open-quote;
        }

        &::after {
            content: close-quote;
        }
    }
}