.card-slider {
    .slick-slider {
        padding-bottom: 2.5625rem;
    }

    .slick-slide {
        padding: 0.9375rem;
    }

    .slick-list {
        margin: -0.9375rem;
        padding-bottom: 0.9375rem;
    }

    .slick-arrow {
        top: auto;
        bottom: 0;
        padding: 0;
        color: var(--black);
        font-size: 1.125rem;
        transform: translateY(0);

        &.slick-prev {
            left: auto;
            right: 2.5rem;
        }

        &.slick-next {
            right: 0;
        }

        &.slick-disabled {
            cursor: default;
            opacity: 0.5;
        }
    }

    .arrow-link {
        position: absolute;
        bottom: 0;
        left: 0.9375rem;
    }

    &--no-link {
        .slick-arrow {
            &.slick-prev {
                right: auto;
                left: calc(50% - 1.875rem);
            }

            &.slick-next {
                right: calc(50% - 1.875rem);
            }
        }
    }
}
