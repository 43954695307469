.properties-controls {
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        padding: 1rem 1.75rem;
        border-radius: 0.5rem;
        font-family: 'Rubik', Arial, sans-serif;
        background-color: var(--white);
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
    }

    &__total,
    &__nav {
        font-weight: 500;
        font-size: 1.125rem;
    }

    &__radios {
        display: flex;
        gap: 1rem;

        & > .per-page,
        & > .order {
            min-width: 200px;
        }

        & > .sort {
            min-width: 250px;
        }
    }

    &__nav {
        &-prev,
        &-next {
            &:disabled {
                color: var(--brand-mid-gray);
            }

            padding: 0.75rem 1rem;
            font-size: 1rem;

            &:hover:not(:disabled) {
                color: var(--brand-cyan);
            }
        }

        &-next {
            margin-right: -1rem;
        }
    }
}

@media screen and (max-width: 1239px) {
    .properties-controls {
        &__wrapper {
            flex-wrap: wrap;
        }
        &__total {
            order: 1;
        }

        &__radios {
            order: 0;
            flex-basis: 100%;

            & > .form-dropdown {
                flex-grow: 1;
                flex-basis: 33.33333%;
                min-width: auto;
            }
        }

        &__nav {
            order: 2;
        }
    }
}

@media screen and (max-width: 991px) {
    .properties-controls {
        &__radios {
            flex-wrap: wrap;

            & > .form-dropdown {
                &.per-page {
                    order: 0;
                    flex-basis: 48%;
                }
                &.sort {
                    order: 2;
                    flex-basis: 100%;
                }
                &.order {
                    order: 1;
                    flex-basis: 48%;
                }
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .properties-controls {

        &__wrapper {
            padding: 1rem;
        }

        &__total,
        &__nav {
            flex-basis: 100%;
        }

        &__radios {
            order: 1;

            & > .form-dropdown {
                &.per-page {
                    order: 0;
                    flex-basis: 100%;
                }
                &.sort {
                    order: 1;
                    flex-basis: 100%;
                }
                &.order {
                    order: 2;
                    flex-basis: 100%;
                }
            }
        }

        &__total {
            text-align: center;
            order: 0;
        }

        &__nav {
            display: flex;
            align-items: center;
            justify-content: center;
            order: 2;

            &-next {
                margin-right: 0;
            }
        }
    }
}