.info-bubble {
    pointer-events: all;
    display: inline-flex;

    &__icon {
        cursor: help;
        color: var(--brand-mid-gray);
        line-height: 0;
        transition: color 0.25s var(--theme-main-bezier);
    }

    &__bubble {
        z-index: 999;
        position: fixed;
        width: 100%;
        max-width: 320px;
        padding: 0.625rem 0.9375rem;
        border-radius: 0.5rem;
        border: 1px solid var(--brand-gray);
        visibility: hidden;
        opacity: 0;
        font-family: 'Rubik', Arial, sans-serif;
        font-size: 0.875rem;
        background-color: var(--white);
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
        transition: opacity 0.25s var(--theme-main-bezier);

        strong {
            font-weight: 500;
        }

        & > p {
            font-size: inherit;

            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
        }
    }

    &.active {
        .info-bubble {
            &__icon {
                color: var(--brand-cyan);
            }

            &__bubble {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
