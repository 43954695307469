.property-price {
    display: flex;
    align-items: flex-end;
    line-height: 1;

    &__current {
        font-weight: 400;
        font-size: 1.5rem;
        color: var(--brand-cyan);

        &.discount {
            color: var(--green);
        }
    }

    &__old {
        margin-left: 0.5rem;
        color: var(--red);
        text-decoration: line-through;
    }
}