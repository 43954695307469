
// Slick Slider
.slick {
    &-list,
    &-slider,
    &-track {
        position: relative;
        display: block;
    }

    &-loading &-slide,
    &-loading &-track {
        visibility: hidden;
    }

    &-slider {
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    &-list {
        overflow: hidden;
        margin: 0;
        padding: 0;

        &:focus {
            outline: 0;
        }

        &.dragging {
            // cursor: pointer;
            cursor: grab;
            cursor: hand;
        }
    }

    &-slider &-list,
    &-slider &-track {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    &-track {
        display: flex;
        top: 0;
        left: 0;

        &::before,
        &::after {
            display: table;
            content: '';
        }

        &:after {
            clear: both;
        }
    }

    &-slide {
        display: none;
        float: left;
        height: inherit;
        min-height: 1px;

        & > div {
            height: 100%;
        }

        img {
            display: block;
        }

        &.slick-loading img {
            display: none;
        }

        &.dragging img {
            pointer-events: none;
        }
    }

    &-initialized &-slide {
        display: block;
    }

    &-vertical &-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

    &-arrow {
        z-index: 2;
        cursor: pointer;
        position: absolute;
        top: 50%;
        padding: 1rem;
        color: var(--jak-gray-blue);
        line-height: 0;
        transform: translateY(-50%);
        transition: color 0.2s var(--jak-bezier-custom);
        
        &:hover {
            color: var(--jak-mid-blue);
        }

        &.slick {
            &-prev {
                left: -1rem;
            }

            &-next {
                right: -1rem;
            }
        }

        &.slick-hidden {
            display: none;
        }

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    [dir='rtl'] &-slide {
        float: right;
    }
}