.article-card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 0.5rem;
    color: var(--brand-dark-purple);
    background-color: var(--white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.25s var(--theme-main-bezier);

    &:hover {
        color: var(--brand-dark-purple);
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);

        .article-card {
            &__image {
                transform: scale(1.1);
            }

            &__title {
                color: var(--brand-orange);
            }
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__figure {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: (10 / 17) * 100%;

        &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 4rem;
            background: linear-gradient(0deg, rgba(black, 0.25), transparent);
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: transform 0.25s var(--theme-main-bezier);
    }

    &__info {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 1.25rem 1.5rem;
    }

    &__title {
        margin-bottom: 0.5rem;
        font-family: 'Roboto', Arial, sans-serif;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.2;
        color: var(--brand-dark-purple);
        transition: color 0.25s var(--theme-main-bezier);
    }

    &__description {
        font-size: 0.875rem;
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    &__details {
        display: flex;
        justify-content: space-between;
        padding-top: 1.25rem;
        margin-top: auto;
        border-top: 1px solid var(--brand-mid-gray);
        font-family: 'Rubik', Arial, sans-serif;
        line-height: 1;
    }

    &__date,
    &__category {
        display: flex;
        font-size: 0.875rem;
    }

    &__label {
        color: var(--brand-dark-gray);
        margin-right: 0.25rem;
    }

    &__value {
        font-weight: 500;
    }
}

@media screen and (max-width: 767.98px) {
    .article-card {
        &__info {
            padding: 1rem;
        }
    }
}