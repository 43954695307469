.social {
    display: flex;

    &__link {
        display: inline-flex;
        
        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:hover,
        &:focus {
            color: var(--yellow);
        }
    }
}