.footer {
    margin-top: auto;
    color: var(--white);
    font-family: 'Rubik', Arial, sans-serif;

    &__main {
        padding: 4rem 0;
        background-color: var(--brand-dark-purple);
    }

    &__bottom {
        padding: 1.75rem 0;
        font-size: 0.875rem;
        background-color: var(--brand-light-purple);

        &-author {
            & > a {
                &:hover {
                    color: var(--yellow);
                }
            }
        }
    }

    &__list {
        font-size: 0.875rem;

        & > li:not(:last-child) {
            margin-bottom: 0.625rem;
        }

        .link {
            &:hover {
                color: var(--yellow)
            }
        }
    }

    &__phone {
        display: inline-flex;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1;

        & > svg {
            font-size: 1rem;
            margin-right: 0.5rem;
        }
    }

    &__social {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 575.98px) {
    .footer {
        &__main {
            padding: 2rem 0;
        }

        &__list > li:not(:last-child) {
            margin-bottom: 0.5rem;
        }

        &__social {
            justify-content: center;
        }

        .newsletter {
            justify-content: center;

            &__input {
                flex-basis: 100%;
                order: 0;
                margin-right: 0;
                margin-bottom: 1rem;
            }

            &__btn {
                order: 2;
            }

            &__consent {
                order: 1;
                flex-basis: 100%;
                margin-top: 0;
                margin-bottom: 1rem;
            }

            .inquiry-form__error {
                margin-top: 0.5rem;
                margin-bottom: 0;
            }
        }
    }
}