.header {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 1920px;
    min-height: 4.375rem;
    padding: 1.125rem 0;
    color: var(--white);
    background-color: transparent;
    transition: all 0.2s var(--theme-main-bezier);

    &__logo {
        display: inline-flex;

        &-col {
            z-index: 2;
            display: flex;
        }
    }

    &__nav-col {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__social {
        z-index: 1;
        font-size: 1.125rem;
    }

    &__phone {
        z-index: 1;
        display: inline-flex;
        align-items: center;
        padding: 0.375rem 0.625rem;
        height: 2rem;
        border-radius: 10rem;
        color: var(--white);
        font-family: 'Rubik', Arial, sans-serif;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1;
        background-color: var(--brand-orange);
        transition: background-color 0.2s var(--theme-main-bezier);

        &:hover {
            background-color: var(--brand-orange-dark);

            & > svg {
                animation: phone-ring 0.25s var(--theme-main-bezier) infinite alternate;
            }
        }

        & > svg {
            font-size: 0.875rem;
            margin-right: 0.5rem;
        }
    }

    &.scrolled {
        padding: 0.625rem 0;
        background-color: var(--brand-dark-purple);

        .nav--tablet .nav-wrapper {
            padding-top: 84px;
        }

        &__logo {
            // transform: scale(0.75) translateY(-5px) translateX(-40px);
        }
    }
}

@keyframes phone-ring {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(15deg);
    }
}

@media screen and (max-width: 767.98px) {
    .header {
        &.scrolled {
            .nav--tablet .nav-wrapper {
                padding-top: 73px;
            }
        }
    }
}
