.blog-popular {
    &__heading {
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid var(--brand-mid-gray);
        font-size: 1.5rem;
    }

    &__article {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    &__link {
        display: flex;
        padding: 1rem 1.25rem;
        border-radius: 0.5rem;
        background-color: var(--white);
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.25s var(--theme-main-bezier);

        &:hover {
            color: var(--brand-dark-purple);
            box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        }
    }

    &__number {
        display: flex;
        align-items: center;
        margin-right: 1.25rem;
        font-size: 3.5rem;
        line-height: 0.75;
        color: var(--brand-mid-gray);
        font-variant-numeric: tabular-nums;
    }

    &__details {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__title {
        display: flex;
        margin-bottom: 0.25rem;
        color: var(--brand-cyan);
        font-size: 1.125rem;
    }

    &__content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Rubik', Arial, sans-serif;
        font-size: 0.875rem;
        line-height: 1.25;
    }
}
