.inner-page-content {
    padding: 3.75rem 0;
    background-color: var(--brand-light-gray);

    &__aside > *:not(:last-child),
    &__main > *:not(:last-child) {
        margin-bottom: 3.75rem;
    }

    &__aside {
        padding-left: 1.875rem;
    }
}

@media screen and (max-width: 1619px) {
    .inner-page-content {
        &__aside {
            padding-left: 0;
        }
    }
}

@media screen and (max-width: 1239px) {
    .inner-page-content {
        &__aside > *:not(:last-child),
        &__main > *:not(:last-child),
        &__main {
            margin-bottom: 1.875rem;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .inner-page-content {
        padding: 2rem 0;
    }
}