.arrow-link {
    display: inline-flex;
    align-items: center;
    padding-right: 0.25rem;
    font-family: 'Rubik', Arial, sans-serif;
    line-height: 1.5;

    &:hover {
        & > svg {
            transform: translateX(0.25rem);
        }
    }

    & > svg {
        margin-left: 0.5rem;
        transform: translateX(0);
        transition: transform 0.25s var(--theme-main-bezier);
    }
}