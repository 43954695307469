.mortgage-calculator {
    padding: 1.875rem;
    border-radius: 0.5rem;
    border: 1px solid var(--brand-gray);
    font-family: 'Rubik', Arial, sans-serif;
    background-color: var(--white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

    &__heading {
        margin-bottom: 1rem;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }

    &__field {
        flex-grow: 1;
        flex-basis: calc(50% - 0.5rem);
    }

    &__label {
        position: relative;
        width: 100%;

        &-small {
            pointer-events: all;
            position: relative;
            display: inline-flex;
            align-items: center;

            &-icon {
                pointer-events: none;
                position: absolute;
                right: 0.375rem;
                font-size: 0.625rem;
                line-height: 1;
            }
        }
    }

    &__label-text {
        pointer-events: none;
        position: absolute;
        top: 0.625rem;
        left: 0.9375rem;
        font-size: 0.875rem;
    }

    &__label-icon {
        pointer-events: none;
        position: absolute;
        top: 2rem;
        right: 0.9375rem;
        font-size: 1rem;
        color: var(--brand-cyan);
    }

    &__input {
        width: 100%;
        padding: 2rem 1.9375rem 0.625rem 0.9375rem;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1;

        &-small {
            padding: 0 1rem 0 0.25rem;
            line-height: 1;
            width: 3.5rem;
        }
    }

    &__monthly {
        display: flex;
        align-items: flex-end;
        padding: 0.9375rem;
        border-radius: 0.25rem;
        color: var(--white);
        line-height: 1;
        background-color: var(--brand-cyan);

        & > .payment {
            margin-right: 0.25rem;
            font-size: 2rem;
            line-height: 0.75;
        }
    }
}

@media screen and (max-width: 1619px) {
    .mortgage-calculator {
        &__field {
            flex-basis: 100%;
        }
    }
}

@media screen and (max-width: 1239px) {
    .mortgage-calculator {
        &__field {
            flex-basis: calc(50% - 0.5rem);
        }
    }
}

@media screen and (max-width: 575.98px) {
    .mortgage-calculator {
        padding: 1rem;
    }
}

@media screen and (max-width: 463.98px) {
    .mortgage-calculator {
        &__field {
            flex-basis: 100%;
        }
    }
}