// Fonts
@import url('//fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Rubik:ital,wght@0,400;0,500;1,400;1,500&display=swap');

// Root
:root {
    --theme-main-bezier: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

::selection {
    color: var(--white);
    background-color: var(--brand-cyan);
}

// Base Styles
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*:focus {
    outline: none;
}

.ReactModalPortal {
    .Toastify__close-button {
        background-color: var(--red);
    }

    p {
        font-size: 1rem;
        line-height: 1.25;
    }
}

body {
    color: var(--brand-dark-purple);
    font-family: 'Roboto', Arial, sans-serif;

    &.menu-open,
    &.ReactModal__Body--open {
        overflow: hidden;
    }
}

main,
footer,
section {
    position: relative;
}

button {
    padding: 0;
    border: none;
    background-color: transparent;

    &:focus {
        outline: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Rubik', Arial, sans-serif;
    font-weight: 500;
}

p {
    font-size: 1.125rem;
    margin-bottom: 0;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    & > .link {
        color: orange;
    }
}

a {
    color: inherit;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

ul {
    & > .ul-clean {
        list-style: disc;
        margin-left: 1.125rem;
    }
}

figure {
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

svg {
    width: 100%;
    height: 100%;
}

// Forms

label {
    display: inline-flex;
    margin-bottom: 0;
}

input {
    padding: 0.625rem 0.9375rem;
    border-radius: 0.25rem;
    border: 1px solid var(--brand-gray);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }

    &:disabled {
        color: var(--brand-dark-purple);
        background-color: var(--brand-light-gray);
    }

    &:hover,
    &:focus {
        border-color: var(--brand-cyan);
    }
}

textarea {
    padding: 0.625rem 0.9375rem;
    border-radius: 0.25rem;
    border: 1px solid var(--brand-gray);
    line-height: 1.25;

    &:hover,
    &:focus {
        border-color: var(--brand-cyan);
    }
}

input::placeholder,
textarea::placeholder {
    color: var(--brand-dark-gray);
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: var(--brand-dark-gray);
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: var(--brand-dark-gray);
}

input:focus::placeholder,
textarea:focus::placeholder {
    color: var(--brand-gray);
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
    color: var(--brand-gray);
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
    color: var(--brand-gray);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill::first-line {
    font-size: 1rem !important;
    font-family: 'Roboto', Arial, sans-serif !important;
}

@media screen and (max-width: 575.98px) {
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 1.25rem;
    }
    h4 {
        font-size: 1.125rem;
    }
    h5 {
        font-size: 1rem;
    }
    h6 {
        font-size: 0.875rem;
    }
    p {
        font-size: 1rem;
    }
}
