.reviews-slider {
    padding: 4rem 0;

    &__review {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10%;
        font-family: 'Rubik', Arial, sans-serif;
        font-style: italic;
        text-align: center;
    }

    &__content {
        font-size: 1.5rem;
        line-height: 1.2;
    }

    &__author {
        display: inline-flex;
        align-items: center;
        margin-top: 1.25rem;
        font-size: 1.5rem;
        font-weight: 500;

        &::before,
        &::after {
            content: '';
            width: 1.5rem;
            height: 3px;
            opacity: 0;
            background-color: var(--brand-dark-purple);
        }

        &::before {
            margin-right: 1.5rem;
            transform: translateX(-2rem);
        }

        &::after {
            margin-left: 1.5rem;
            transform: translateX(2rem);
        }
    }

    .slick-slider {
        &::before,
        &::after {
            content: '';
            z-index: 1;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 10%;
            pointer-events: none;
        }

        &::before {
            left: 0;
            background: linear-gradient(90deg, var(--brand-light-gray), transparent);
        }

        &::after {
            right: 0;
            background: linear-gradient(-90deg, var(--brand-light-gray), transparent);
        }
    }

    .slick-track {
        display: flex;
        align-items: center;
    }

    .slick-slide {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 0.25s var(--theme-main-bezier) 0.25s, transform 0.5s var(--theme-main-bezier);

        &.slick-active {
            opacity: 1;
            transform: scale(1);
            transition: opacity 1.25s var(--theme-main-bezier) 0.25s, transform 1s var(--theme-main-bezier) 0.5s;

            .reviews-slider {
                &__author {
                    &::before,
                    &::after {
                        opacity: 1;
                        transform: translateX(0);
                        transition: opacity 1s var(--theme-main-bezier) 0.5s, transform 1s var(--theme-main-bezier) 0.5s;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .reviews-slider {
        padding: 2rem 0;

        &__review {
            padding: 0;
        }

        &__content {
            font-size: 1.125rem;
            line-height: 1.375;
        }

        &__author {
            margin-top: 1rem;
            font-size: 1.125rem;

            &::before,
            &::after {
                content: '';
                width: 1rem;
                height: 3px;
                opacity: 0;
                background-color: var(--brand-dark-purple);
            }

            &::before {
                margin-right: 1rem;
                transform: translateX(-2rem);
            }

            &::after {
                margin-left: 1rem;
                transform: translateX(2rem);
            }
        }

        .slick-slider {
            &::before,
            &::after {
                display: none;
            }
        }
    }
}
