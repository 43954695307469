.search-bar {
    z-index: 2;

    &__wrapper {
        display: flex;
        border-radius: 0.5rem;
        background-color: var(--white);
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
        transform: translateY(-50%);
    }

    &__inner {
        position: relative;
        display: flex;
        padding: 1rem;
        flex-grow: 1;
        gap: 1rem;

        & > .form-dropdown {
            flex-basis: 20%;
        }
    }

    &__clear-all {
        position: absolute;
        top: -1rem;
        right: -1rem;
        width: 2rem;
        height: 2rem;
        border-radius: 10rem;
        color: var(--white);
        background-color: #dc3545;
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);

        &:hover {
            background-color: #c02534;
        }
    }

    &__submit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        color: var(--white);
        font-family: 'Rubik', Arial, sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        background-color: var(--brand-cyan);
        transition: background-color 0.25s var(--theme-main-bezier);

        &:hover {
            color: var(--white);
            background-color: var(--brand-orange);

            & > svg {
                transform: scale(1.5) translateX(-0.5rem);
            }
        }

        & > svg {
            font-size: 1.25rem;
            margin-right: 0.625rem;
            transform: scale(1) translateX(0);
            transition: transform 0.25s var(--theme-main-bezier);
        }
    }

    .form-dropdown {
        flex-grow: 1;
    }

    &--static {
        .search-bar {
            &__inner {
                & > .form-dropdown {
                    flex-basis: unset;
                }
            }
        }
    }
}

@media screen and (max-width: 1619px) {
    .search-bar {
        &__wrapper {
            transform: translateY(-2rem);
        }

        &__inner {
            flex-wrap: wrap;
        }

        &__offer-types {
            display: flex;
            order: 0;
            flex-basis: 48%;
            flex-grow: 1;

            & > .custom-radio {
                display: flex;
                justify-content: center;
                flex-grow: 1;
            }
        }

        &__size {
            order: 1;
            flex-basis: 22.5% !important;
        }
        &__price {
            order: 2;
            flex-basis: 22.5% !important;
        }

        &__property-types {
            order: 3;
            flex-basis: calc(50% - 1rem) !important;
        }

        &__other {
            order: 4;
            flex-basis: calc(50% - 1rem) !important;
            flex-grow: 1;
        }
    }
}

@media screen and (max-width: 1239px) {
    .search-bar {
        &__property-types {
            order: 3;
            flex-basis: calc(33.33333% - 1rem) !important;
        }

        &__other {
            order: 4;
            flex-basis: calc(33.33333% - 1rem) !important;
            flex-grow: 1;
        }

        &__submit {
            border-radius: 0.25rem;
            font-size: 1.125rem;
            order: 5;
            flex-basis: calc(33.33333% - 1rem) !important;
            flex-grow: 1;
        }

        &--static {
            .search-bar {
                &__wrapper {
                    transform: translateY(-50%);
                    margin-bottom: -2.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .search-bar {
        &__property-types {
            order: 1;
            flex-basis: 48% !important;
        }

        &__size {
            order: 2;
            flex-basis: 48% !important;
        }
        &__price {
            order: 3;
            flex-basis: 48% !important;
        }

        &:not(&--static) {
            .search-bar {
                &__other {
                    &.two-column-checkbox {
                        & > .form-dropdown {
                            &__dropdown {
                                max-height: unset;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        &__submit {
            border-radius: 0.25rem;
            font-size: 1.125rem;
            order: 5;
            flex-basis: calc(33.33333% - 1rem) !important;
            flex-grow: 1;
        }

        &--static {
            .search-bar {
                &__wrapper {
                    margin-bottom: -4.5rem;
                }

                &__other {
                    order: 4;
                    flex-basis: calc(33.33333% - 1rem) !important;
                    flex-grow: 1;

                    &.two-column-checkbox > .form-dropdown__dropdown {
                        left: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .search-bar {
        &__property-types {
            flex-basis: 100% !important;
        }

        &__other {
            flex-basis: 100% !important;

            &.two-column-checkbox {
                & > .form-dropdown {
                    &__dropdown {
                        max-height: unset;
                        width: 100%;

                        & > .form-dropdown {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }

        &__submit {
            padding: 0.625rem;
            flex-basis: 100%;
        }

        &__clear-all {
            right: -0.5rem;
        }

        &--static {
            .search-bar {
                &__wrapper {
                    transform: translateY(calc(-100% - 2rem));
                    margin-bottom: -335px;
                }

                &__other {
                    flex-basis: 100% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 463.98px) {
    .search-bar {
        &--static {
            .search-bar {
                &__wrapper {
                    margin-bottom: -399px;
                }
            }
        }
    }
}
