.service-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 1.875rem;
    border-radius: 0.5rem;
    border: 1px solid var(--brand-gray);
    color: var(--brand-dark-purple);
    background-color: var(--white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

    &__icon {
        margin-bottom: 1.5rem;
        color: var(--brand-orange);
        font-size: 3.5rem;
        line-height: 1;
    }

    &__heading {
        margin-bottom: 0.875rem;
    }
    
    &__description {
        margin-bottom: 0;
        font-size: 1rem;
    }
}