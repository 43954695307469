.blog-article {
    &__details {
        display: flex;
        padding-top: 0.25rem;
        padding-bottom: 1rem;
        margin-bottom: 1.375rem;
        border-bottom: 1px solid var(--brand-mid-gray);
        font-family: 'Rubik', Arial, sans-serif;

        * > span {
            font-weight: 500;
        }
    }

    &__views {
        display: flex;
        align-items: center;

        & > div {
            display: inline-flex;
            align-items: center;
            font-size: 1.125rem;
            color: var(--brand-mid-gray);
        }
    }

    &__heading {
        margin-bottom: 1rem;
    }

    &__figure {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: (9 / 16) * 100%;
        border-radius: 0.5rem;
    }

    &__expand {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        border-bottom-left-radius: 0.5rem;
        font-size: 2.5rem;
        line-height: 1;
        color: var(--white);
        background-color: rgba(0, 0, 0, 0.2);
    }

    &__thumbnail {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__tags {
        padding-top: 1rem;
        margin-top: 2rem;
        border-top: 1px solid var(--brand-mid-gray);
    }

    &__share {
        display: flex;

        & > button {
            margin-left: 0.5rem;
            line-height: 0 !important;
            color: var(--brand-dark-purple) !important;

            &:hover {
                color: var(--brand-orange-dark) !important;
            }

            & > svg {
                font-size: 1.25rem;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .blog-article {
        &__details {
            flex-direction: column;
            align-items: center;
        }
    }
}